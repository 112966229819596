import { render, staticRenderFns } from "./NewebPay.vue?vue&type=template&id=3cfc7852&scoped=true&"
import script from "./NewebPay.vue?vue&type=script&lang=js&"
export * from "./NewebPay.vue?vue&type=script&lang=js&"
import style0 from "./NewebPay.vue?vue&type=style&index=0&id=3cfc7852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cfc7852",
  null
  
)

export default component.exports