<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>
          <h1 class="page_title">{{ $route.meta.title }}</h1>
          <img class="w-24" src="@/assets/images/payments/newebpay_logo.png" alt="newebpay" />
        </div>

        <div v-if="is_API_loaded" class="space-y-10">
          <div class="flex items-center space-x-4"></div>

          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">金流參數設定</h2>
            </div>
            <div class="rightBlock">
              <div>
                <section class="grid grid-cols-1 gap-4">
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">商店代號 *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="商店代號"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.sid"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">金流介接 HashKey *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="金流介接 HashKey"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.hashkey"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">金流介接 HashIV *</h4>
                    </div>
                    <ValidationProvider
                      tag="div"
                      class="w-full relative"
                      mode="aggressive"
                      name="金流介接 HashIV"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vs-input v-model.trim="setting.hashiv"></vs-input>
                      <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="flex items-center justify-between mb-1">
                      <h4 class="h4">銀聯卡</h4>
                    </div>
                    <vs-select v-model="setting.unionpay">
                      <vs-option label="啟用" :value="1">啟用</vs-option>
                      <vs-option label="不啟用" :value="0">不啟用</vs-option>
                    </vs-select>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <hr />
          <section class="wrap">
            <div class="leftBlock">
              <h2 class="h2 sm:mt-4">收款方式</h2>
            </div>
            <div class="rightBlock" style="padding: 16px 0">
              <div>
                <collapse-item class="px-4">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox.creditCard" @click.stop=""></vs-checkbox>
                      <p>線上刷卡 (VISA, MasterCard, JCB)</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的付款名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="顯示的付款名稱"
                        vid="1"
                        :rules="`${checkbox.creditCard ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="creditCard.title" maxlength="30" placeholder="線上刷卡 (VISA, MasterCard, JCB)"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款期限</h4>
                      </div>
                      <div>
                        <vs-select v-model="creditCard.pday">
                          <vs-option label="1天" :value="1">1天</vs-option>
                          <vs-option label="3天" :value="3">3天</vs-option>
                          <vs-option label="7天" :value="7">7天</vs-option>
                          <vs-option label="14天" :value="14">14天</vs-option>
                          <vs-option label="30天" :value="30">30天</vs-option>
                          <vs-option label="60天" :value="60">60天</vs-option>
                        </vs-select>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="付款說明"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea placeholder="填寫顧客於結帳時的提示或說明" height="100px" v-model="creditCard.note"></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </section>
                </collapse-item>
                <collapse-item class="px-4">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox.ATM" @click.stop=""></vs-checkbox>
                      <p>ATM 轉帳</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的付款名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="顯示的付款名稱"
                        vid="2"
                        :rules="`${checkbox.ATM ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="ATM.title" maxlength="30" placeholder="ATM 轉帳"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款期限</h4>
                      </div>
                      <div>
                        <vs-select v-model="ATM.pday">
                          <vs-option label="1天" :value="1">1天</vs-option>
                          <vs-option label="3天" :value="3">3天</vs-option>
                          <vs-option label="7天" :value="7">7天</vs-option>
                          <vs-option label="14天" :value="14">14天</vs-option>
                          <vs-option label="30天" :value="30">30天</vs-option>
                          <vs-option label="60天" :value="60">60天</vs-option>
                        </vs-select>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="付款說明"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea placeholder="填寫顧客於結帳時的提示或說明" height="100px" v-model="ATM.note"></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </section>
                </collapse-item>
                <!-- <collapse-item class="px-4">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox.webATM" @click.stop=""></vs-checkbox>
                      <p>網路 ATM</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的付款名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="顯示的付款名稱"
                        vid="3"
                        :rules="`${checkbox.webATM ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="webATM.title" maxlength="30" placeholder="網路 ATM"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款期限</h4>
                      </div>
                      <div>
                        <vs-select v-model="webATM.pday">
                          <vs-option label="1天" :value="1">1天</vs-option>
                          <vs-option label="3天" :value="3">3天</vs-option>
                          <vs-option label="7天" :value="7">7天</vs-option>
                          <vs-option label="14天" :value="14">14天</vs-option>
                          <vs-option label="30天" :value="30">30天</vs-option>
                          <vs-option label="60天" :value="60">60天</vs-option>
                        </vs-select>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="付款說明"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea placeholder="填寫顧客於結帳時的提示或說明" height="100px" v-model="webATM.note"></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </section>
                </collapse-item> -->
                <collapse-item class="px-4">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox.code1" @click.stop=""></vs-checkbox>
                      <p>超商代碼</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的付款名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="顯示的付款名稱"
                        vid="4"
                        :rules="`${checkbox.code1 ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="code1.title" maxlength="30" placeholder="超商代碼"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款期限</h4>
                      </div>
                      <div>
                        <vs-select v-model="code1.pday">
                          <vs-option label="1天" :value="1">1天</vs-option>
                          <vs-option label="3天" :value="3">3天</vs-option>
                          <vs-option label="7天" :value="7">7天</vs-option>
                          <vs-option label="14天" :value="14">14天</vs-option>
                          <vs-option label="30天" :value="30">30天</vs-option>
                          <vs-option label="60天" :value="60">60天</vs-option>
                        </vs-select>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="付款說明"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea placeholder="填寫顧客於結帳時的提示或說明" height="100px" v-model="code1.note"></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </section>
                </collapse-item>
                <collapse-item class="px-4">
                  <template #title>
                    <div class="flex items-center space-x-2">
                      <vs-checkbox success v-model="checkbox.code2" @click.stop=""></vs-checkbox>
                      <p>超商條碼</p>
                    </div>
                  </template>
                  <section class="py-4 pl-8 space-y-4">
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">顯示的付款名稱</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="顯示的付款名稱"
                        vid="5"
                        :rules="`${checkbox.code2 ? 'required' : ''}|max:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="code2.title" maxlength="30" placeholder="超商條碼"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款期限</h4>
                      </div>
                      <div>
                        <vs-select v-model="code2.pday">
                          <vs-option label="1天" :value="1">1天</vs-option>
                          <vs-option label="3天" :value="3">3天</vs-option>
                          <vs-option label="7天" :value="7">7天</vs-option>
                          <vs-option label="14天" :value="14">14天</vs-option>
                          <vs-option label="30天" :value="30">30天</vs-option>
                          <vs-option label="60天" :value="60">60天</vs-option>
                        </vs-select>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center justify-between mb-1">
                        <h4 class="h4">付款說明</h4>
                        <p class="text-gray-400">※ 會顯示於結帳頁面上。</p>
                      </div>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        mode="aggressive"
                        name="付款說明"
                        rules="max:200"
                        v-slot="{ errors }"
                      >
                        <myTextarea placeholder="填寫顧客於結帳時的提示或說明" height="100px" v-model="code2.note"></myTextarea>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </section>
                </collapse-item>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-between">
            <router-link :to="{ name: 'store_payments' }">
              <vs-button border size="large">取消 </vs-button>
            </router-link>
            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="saveEdit(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-4">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import collapseItem from '@/components/ui_components/collapse-item'
export default {
  name: 'newebpay',
  components: {
    collapseItem,
  },
  data() {
    return {
      is_API_loaded: false,
      setting: {},
      // 收款方式checkbox
      checkbox: {
        creditCard: false,
        ATM: false,
        // webATM: false,
        code1: false,
        code2: false,
      },
      // 收款方式預設值，
      creditCard: {
        pterms: 1,
        title: '線上刷卡 (VISA, MasterCard, JCB)',
        pday: 3,
        note: '',
      },
      ATM: {
        pterms: 2,
        title: 'ATM 轉帳',
        pday: 3,
        note: '',
      },
      // webATM: {
      //   pterms: 3,
      //   title: '網路 ATM',
      //   pday: 3,
      //   note: '',
      // },
      code1: {
        pterms: 4,
        title: '超商代碼',
        pday: 3,
        note: '',
      },
      code2: {
        pterms: 5,
        title: '超商條碼',
        pday: 3,
        note: '',
      },
      allData: {},
    }
  },
  created() {
    this.loadSetting()
  },
  methods: {
    // 4.4.1.3 金流第三方資料-讀取
    loadSetting() {
      this.$axios({
        url: 'front/set/app/getGoldThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          pthird: 2, // 讀取藍新
        },
      }).then((res) => {
        if (res.data.Success) {
          const allDataAry = res.data.Data.alldata

          allDataAry.forEach((item) => {
            if (item.pterms === 1) {
              this.creditCard = item
              this.checkbox.creditCard = true
            } else if (item.pterms === 2) {
              this.ATM = item
              this.checkbox.ATM = true
            } else if (item.pterms === 3) {
              // this.webATM = item
              // this.checkbox.webATM = true
            } else if (item.pterms === 4) {
              this.code1 = item
              this.checkbox.code1 = true
            } else if (item.pterms === 5) {
              this.code2 = item
              this.checkbox.code2 = true
            }
          })

          this.setting = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.4.1.4 金流第三方資料-修改
    saveEdit(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      const allData = []
      for (let key in this.checkbox) {
        if (this.checkbox[key]) allData.push(this[key])
      }

      this.$axios({
        url: 'front/set/app/uGoldThirdData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          pthird: 2,
          sid: this.setting.sid,
          hashkey: this.setting.hashkey,
          hashiv: this.setting.hashiv,
          unionpay: this.setting.unionpay,
          alldata: JSON.stringify(allData),
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.loadSetting()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-6;
}
</style>
